import { Button, Container, Grid, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "next-export-i18n";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import disableScroll from "disable-scroll";
import dynamic from "next/dynamic";
const BottomSheetDrawer = dynamic(
  () => import("../../src/components/BottomSheetDrawer")
);
const BottomNav = dynamic(
  () => import("../../src/components/BottomNavigation")
);
const SwipeableImageHome = dynamic(
  () => import("../../src/components/SwipeableImageHome")
);
const SwipeableBanner = dynamic(
  () => import("../../src/components/SwipeableBanner")
);
import CardMembership from "../../src/components/CardMembership";
import SkeletonHome from "../../src/components/Skeleton/SkeletonHome";
import { DataBannerDto } from "../../src/libs/banner";
import { GuestMembershipByGroupIdDto } from "../../src/libs/membership";
import { getBanner, getTenantProfile } from "../../src/services/common";
import { getGuestMembershipByGroupId } from "../../src/services/membership";
import { getTotalPoints } from "../../src/services/points";
import { GetGuestVoucher } from "../../src/services/voucher";
import { getGuestTotalBalances } from "../../src/services/wallet";
import useStyles from "../../styles/home";
import { TenantProfileDto } from "../../src/libs/tenant";

type Props = {
  isLogin?: boolean;
  dataBanner?: any;
};

function Home(props: Props) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const router = useRouter();
  const lang = router.query.lang || "id";
  const [loading, setLoading] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [points, setPoints] = useState<number>(0);
  const [wallet, setWallet] = useState<number>(0);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [totalVoucher, setTotalVoucher] = useState<number>(0);
  const [dataBanner, setDataBanner] = useState<DataBannerDto[]>([]);
  const [membershipData, setMembershipData] =
    useState<GuestMembershipByGroupIdDto["data"]>();
  const [tenantProfile, setTenantProfile] = useState<TenantProfileDto>(Object);

  const fetchBanner = async () => {
    setLoading(true);
    try {
      const response = await getBanner();
      setDataBanner(response.data);
    } catch (error) {}
  };

  const getConfigTenant = async () => {
    try {
      const response = await getTenantProfile();
      setTenantProfile(response.data);
    } catch (error) {}
  };

  const fetchDataMembership = async () => {
    if (!localStorage.getItem("token")) {
      setIsLogin(false);
      setLoading(false);
      return;
    }
    const dataLogin = JSON.parse(localStorage.getItem("data_login") || "{}");
    if (tenantProfile?.pointConfiguration?.isEnabled) {
      const point = await getTotalPoints();
      setPoints(point.data.totalPoints);
    }
    if (tenantProfile?.walletConfig?.isEnabled) {
      const balance = await getGuestTotalBalances();
      setWallet(balance.data.total_balances);
    }
    if (tenantProfile?.membershipConfig?.isEnabled) {
      const membership = await getGuestMembershipByGroupId(
        dataLogin.guest.memberships?.[0]
      );
      setMembershipData(membership.data);
    }
    const voucher = await GetGuestVoucher({
      page: "1",
      per_page: "100",
    });

    setTotalVoucher(voucher.meta.total_data);
    setIsLogin(true);
    setLoading(false);
  };

  useEffect(() => {
    if (process.env.PWA_MODE !== "instant") {
      setLoading(true);
      fetchBanner();
    }
  }, []);

  useEffect(() => {
    if (process.env.PWA_MODE !== "instant") fetchDataMembership();
  }, [tenantProfile]);

  useEffect(() => {
    if (isLogin && process.env.PWA_MODE !== "instant") {
      getConfigTenant();
    }
  }, [isLogin]);

  useEffect(() => {
    localStorage.removeItem("isSingleHotel");
    localStorage.removeItem("isSingleCity");
    localStorage.removeItem("back");
    localStorage.removeItem("fromSelectDate");
    disableScroll.off();
  }, []);

  return (
    <>
      {loading ? (
        <SkeletonHome isLogin={isLogin} />
      ) : (
        <Fragment>
          <Container maxWidth="xs" className={classes.root}>
            <div className={classes.headerWrapper}>
              <img
                src={
                  process.env.NEXT_PUBLIC_WHITE_LOGO ||
                  "https://pesanio-bucket.s3.ap-southeast-1.amazonaws.com/98024a42abc3dad975ca09d01-2024-04-17T06%3A33%3A49.377Z"
                }
                className={classes.logo}
                alt="logo-tenant"
              />
              <SwipeableImageHome />
              <div
                className={classes.wrapperSeacrhField}
                onClick={() =>
                  router.push(`/hotels/rooms/search-location?lang=${lang}`)
                }
              >
                <SearchIcon style={{ color: "#FFF" }} />
                <Typography variant="body2" className={classes.searchField}>
                  {t("home.find-hotel-location")}
                </Typography>
              </div>
            </div>
            <div className={classes.rootWrapper}>
              <div className={classes.divider}></div>
              <div className={classes.searchSection}>
                <div>
                  <CardMembership
                    wallet={wallet}
                    points={points}
                    totalVoucher={totalVoucher}
                    membershipData={membershipData}
                    isLogin={isLogin}
                    tenantProfile={tenantProfile}
                  />
                  <div className={classes.searchKeterangan}>
                    <Typography className={classes.olehOlehPopuler}>
                      {t("home.what-do-yo-want-today")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.pilihLayananWrapper}>
                <Grid container style={{ marginTop: 18 }}>
                  <Grid item xs={3}>
                    <Link href={`/hotels/rooms/search-location?lang=${lang}`}>
                      <Button
                        className={classes.buttonPilihanLayanan}
                        fullWidth
                      >
                        <img
                          src="/img/room.svg"
                          alt="room-icon"
                          width={42}
                          height={42}
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#52575C",
                          }}
                        >
                          Rooms
                        </Typography>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href={`/hotels/foods/search-location?lang=${lang}`}>
                      <Button
                        className={classes.buttonPilihanLayanan}
                        fullWidth
                      >
                        <img
                          src="/img/food.svg"
                          alt="food-icon"
                          width={42}
                          height={42}
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#52575C",
                          }}
                        >
                          Foods
                        </Typography>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link
                      href={`/hotels/souvenirs/search-location?lang=${lang}`}
                    >
                      <Button
                        className={classes.buttonPilihanLayanan}
                        fullWidth
                      >
                        <img
                          src="/img/gift.svg"
                          alt="souvenir-icon"
                          width={42}
                          height={42}
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#52575C",
                          }}
                        >
                          Souvenirs
                        </Typography>
                      </Button>
                    </Link>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      className={classes.buttonPilihanLayanan}
                      fullWidth
                      onClick={() => setOpenMore(true)}
                    >
                      <img
                        src="/img/more.svg"
                        alt="more-icon"
                        width={42}
                        height={42}
                      />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#52575C",
                        }}
                      >
                        More
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </div>
              {dataBanner?.length ? (
                <SwipeableBanner dataBanner={dataBanner} />
              ) : null}

              <BottomSheetDrawer
                onOpen={openMore}
                close={() => setOpenMore(false)}
              >
                <Grid
                  container
                  style={{ rowGap: "9px", padding: "0 16px 50px 16px" }}
                >
                  <Grid item xs={3}>
                    <Link href={`/hotels/rooms/search-location?lang=${lang}`}>
                      <Button
                        className={classes.buttonPilihanLayanan}
                        fullWidth
                      >
                        <div>
                          <img
                            src="/img/room.svg"
                            alt="room-icon"
                            width={42}
                            height={42}
                          />
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "12px",
                              color: "#52575C",
                            }}
                          >
                            Rooms
                          </Typography>
                        </div>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link href={`/hotels/foods/search-location?lang=${lang}`}>
                      <Button
                        className={classes.buttonPilihanLayanan}
                        fullWidth
                      >
                        <div>
                          <img
                            src="/img/food.svg"
                            alt="food-icon"
                            width={42}
                            height={42}
                          />
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "12px",
                              color: "#52575C",
                            }}
                          >
                            Foods
                          </Typography>
                        </div>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Link
                      href={`/hotels/souvenirs/search-location?lang=${lang}`}
                    >
                      <Button
                        className={classes.buttonPilihanLayanan}
                        fullWidth
                      >
                        <div>
                          <img
                            src="/img/gift.svg"
                            alt="souvenir-icon"
                            width={42}
                            height={42}
                          />
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "12px",
                              color: "#52575C",
                            }}
                          >
                            Souvenirs
                          </Typography>
                        </div>
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={3}>
                    <Button className={classes.buttonPilihanLayanan} fullWidth>
                      <img
                        src="/img/spas.svg"
                        alt="spas-icon"
                        width={42}
                        height={42}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "12px",
                          color: "#52575C",
                        }}
                      >
                        Spas
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button className={classes.buttonPilihanLayanan} fullWidth>
                      <img
                        src="/img/serviceIcon.svg"
                        alt="service-icon"
                        width={42}
                        height={42}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "12px",
                          color: "#52575C",
                        }}
                      >
                        Services
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button className={classes.buttonPilihanLayanan} fullWidth>
                      <img
                        src="/img/experienceIcon.svg"
                        alt="experience-icon"
                        width={42}
                        height={42}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "12px",
                          color: "#52575C",
                        }}
                      >
                        Experiences
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button className={classes.buttonPilihanLayanan} fullWidth>
                      <img
                        src="/img/coworkingIcon.svg"
                        alt="coworking-icon"
                        width={42}
                        height={42}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "12px",
                          color: "#52575C",
                        }}
                      >
                        Coworking
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </BottomSheetDrawer>
            </div>
            <div>
              <BottomNav />
            </div>
          </Container>
        </Fragment>
      )}
    </>
  );
}

export default Home;
