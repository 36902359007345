import React, { useState, useEffect, Fragment } from "react";
import useStyles from "../../styles/home";
import { Container, Typography, Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import dynamic from "next/dynamic";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import nextDayDate from "../../src/utils/nextDayDate";
import dateCustom from "../../src/utils/dateCustom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Mousewheel, Keyboard } from "swiper";
import { getBanner } from "../../src/services/common";
import BackgroundHomeIcon from "../../public/img/background-home-icon";
import CheckinIcon from "../../public/img/CheckinIcon";
import CheckoutIcon from "../../public/img/CheckoutIcon";
import Head from "next/head";
import { useTranslation } from "next-export-i18n";
import { useRouter } from "next/router";
import { DataBannerDto } from "../libs/banner";
import { getListCity } from "../services/rooms";
import { CityDto } from "../libs/city";

const BottomNav = dynamic(
  () => import("../../src/components/BottomNavigation"),
  {
    ssr: false,
  }
);

type SelectedLocation = {
  distance: {
    km: number;
    text: string;
  };
  id: string;
  latitude: number;
  longitude: number;
  name: string;
  total_hotels: 1;
};

function HomePageEmbed() {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const lang = router.query.lang || "id";
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] =
    useState<SelectedLocation>(Object);
  const [dateCheckIn, setDateCheckIn] = useState({
    in: new Date(),
    out: nextDayDate(new Date()),
  });
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [dataBanner, setDataBanner] = useState<DataBannerDto[]>([]);
  const [city, setCity] = useState<CityDto[]>([]);

  const rangeNight = (first, end) => {
    let oneDay = 24 * 3600 * 1000;
    let timeDiff = Math.abs(first.getTime() - end.getTime());
    return Math.ceil(timeDiff / oneDay);
  };

  useEffect(() => {
    localStorage.removeItem("isSingleHotel");
  }, []);

  const typeDate = (date) => {
    const vDate = new Date(date);
    const year = vDate.getFullYear();
    const month =
      Number(vDate.getMonth() + 1) < 10
        ? `0${Number(vDate.getMonth() + 1)}`
        : Number(vDate.getMonth() + 1);
    const day =
      Number(vDate.getDate()) < 10
        ? `0${Number(vDate.getDate())}`
        : Number(vDate.getDate());

    return year + "-" + month + "-" + day;
  };

  const handleSearch = () => {
    localStorage.setItem(
      "dateCheckIn",
      JSON.stringify({
        checkin: typeDate(dateCheckIn.in),
        checkout: typeDate(dateCheckIn.out),
      })
    );
    if (selectedLocation !== null) {
      router.push(
        `/hotels/rooms/search-rooms?cityId=${
          selectedLocation.id
        }&checkin=${typeDate(dateCheckIn.in)}&checkout=${typeDate(
          dateCheckIn.out
        )}&lang=${lang}`
      );
    }
  };

  const parseHtml = (html: string): string => {
    const div = document.createElement("div");
    div.innerHTML = html;
    const text = div.textContent || div.innerText || "";
    return `${text.slice(0, 55)} ...`;
  };

  useEffect(() => {
    if (selectedLocation && dateCheckIn.in && dateCheckIn.out) {
      setIsValidate(true);
      return;
    }
    setIsValidate(false);
  }, [selectedLocation, dateCheckIn]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const banner = await getBanner();
      setDataBanner(banner.data);
      setSelectedLocation(JSON.parse(localStorage.getItem("city")));
      setLoading(false);
    })();
  }, []);

  const fetchListCity = async () => {
    setLoading(true);
    try {
      const response = await getListCity("", "");
      setCity(response?.data);
      if (response.data?.length === 1) {
        setSelectedLocation(response.data?.[0]);
        localStorage.setItem("city", JSON.stringify(response.data[0]));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListCity();
  }, []);

  return (
    <>
      {loading ? (
        <SkeletonLoading />
      ) : (
        <Fragment>
          <Head>
            <meta name="description" content="Home page from artotel hotels" />
            <meta name="robots" content="all" />
          </Head>
          <Container maxWidth="xs" className={classes.root}>
            <div className={classes.headerBackgroundWrapper}>
              <div className={classes.backgroundWrapper}>
                <BackgroundHomeIcon />
              </div>
            </div>
            <div className={classes.contentWrapper}>
              <div className={classes.searchLocationWrapper}>
                {city?.length > 1 ? (
                  <Typography variant="body2" className={classes.titleCard}>
                    {t("home.where-do-you-want-to-stay")}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    className={classes.titleCardSingle}
                  >
                    {t("home.choose-checkin-checkout?")}
                  </Typography>
                )}
                <div
                  onClick={() => {
                    if (city?.length > 1) {
                      router.push(`/hotels/rooms/search-location?lang=${lang}`);
                    }
                  }}
                >
                  <div
                    className={
                      city?.length > 1
                        ? classes.areaSearch
                        : classes.areaSearchSingle
                    }
                  >
                    <div style={{ marginRight: 8 }}>
                      <RoomOutlinedIcon color="primary" />
                    </div>
                    <div
                      className={
                        city?.length > 1
                          ? classes.searchLabel
                          : classes.searchLabelSingle
                      }
                    >
                      <div>
                        <Typography
                          className={classes.bold}
                          style={{
                            color: !selectedLocation && "#A0A4A8",
                            fontSize: 14,
                          }}
                        >
                          {selectedLocation
                            ? selectedLocation.name
                            : t("home.find-hotel-location")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Typography variant="body2" className={classes.titleCard}>
                    Check-In
                  </Typography>
                  <div style={{ display: "flex", position: "relative" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        value={dateCheckIn.in}
                        onChange={(date) =>
                          setDateCheckIn({
                            ...dateCheckIn,
                            in: date,
                            out: nextDayDate(date),
                          })
                        }
                        animateYearScrolling
                        minDate={dateCheckIn.in}
                        open={openCheckIn}
                        onClose={() => setOpenCheckIn(false)}
                        TextFieldComponent={() => (
                          <div
                            className={classes.areaSearch}
                            onClick={() => setOpenCheckIn(true)}
                          >
                            <div style={{ marginRight: 8 }}>
                              <CheckinIcon
                                color={process.env.PRIMARY_COLOR || "#2F9CF1"}
                              />
                            </div>
                            <div className={classes.searchLabel}>
                              <div>
                                <Typography
                                  style={{ fontSize: "14px" }}
                                  className={classes.bold}
                                >
                                  {dateCustom(dateCheckIn.in)}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </MuiPickersUtilsProvider>
                    <div className={classes.totalNight}>
                      <img
                        src="/img/moonlight.svg"
                        alt="moon"
                        style={{ marginRight: 4 }}
                      />
                      <Typography variant="caption">
                        {rangeNight(dateCheckIn.in, dateCheckIn.out)}
                      </Typography>
                      <div>
                        <Typography variant="caption">
                          {lang === "en" &&
                          rangeNight(dateCheckIn.in, dateCheckIn.out) > 1
                            ? "Nights"
                            : t("home.night")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Typography variant="body2" className={classes.titleCard}>
                    Check-Out
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={dateCheckIn.out}
                      onChange={(date) =>
                        setDateCheckIn({ ...dateCheckIn, out: date })
                      }
                      animateYearScrolling
                      open={openCheckOut}
                      minDate={nextDayDate(dateCheckIn.in)}
                      onClose={() => setOpenCheckOut(false)}
                      TextFieldComponent={() => (
                        <div
                          className={classes.areaSearch}
                          onClick={() => setOpenCheckOut(true)}
                        >
                          <div style={{ marginRight: 8 }}>
                            <CheckoutIcon
                              color={process.env.PRIMARY_COLOR || "#2F9CF1"}
                            />
                          </div>
                          <div className={classes.searchLabel}>
                            <div>
                              <Typography
                                style={{ fontSize: "14px" }}
                                className={classes.bold}
                              >
                                {dateCustom(dateCheckIn.out)}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    paddingTop: "13px",
                    paddingBottom: "16px",
                    marginTop: "16px",
                  }}
                  onClick={handleSearch}
                  disabled={!isValidate}
                >
                  {t("home.find-rooms")}
                </Button>
              </div>
              <div>
                <Typography
                  style={{
                    marginTop: "32px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginBottom: "16px",
                  }}
                >
                  Promo
                </Typography>
                <Swiper
                  cssMode={true}
                  navigation={true}
                  pagination={true}
                  mousewheel={true}
                  keyboard={true}
                  spaceBetween={30}
                  modules={[Mousewheel, Keyboard]}
                >
                  {dataBanner.map((res) => (
                    <SwiperSlide key={res?.id}>
                      <div
                        className={classes.cardBanner}
                        onClick={() =>
                          router.push(`/detail-promo?id=${res.id}`)
                        }
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "141px",
                            zIndex: "-1",
                            position: "absolute",
                          }}
                        >
                          <img
                            src={res?.image?.url}
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                              objectPosition: "center top",
                            }}
                            alt={`promo-image-${res?.id}`}
                          />
                        </div>
                        <div style={{ padding: "57px 14px 0 10px" }}>
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#FFFFFF",
                            }}
                          >
                            {res.title}
                          </Typography>
                          <div className={classes.promoDescription}>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#FFFFFF",
                                width: "179px",
                                fontWeight: "500",
                              }}
                            >
                              {parseHtml(res?.description)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div style={{ paddingBottom: "100px" }}></div>
            <div>
              <BottomNav />
            </div>
          </Container>
        </Fragment>
      )}
    </>
  );
}

function SkeletonLoading() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Container maxWidth="xs" className={classes.root}>
        <div className={classes.headerWraperLoading}>
          <Skeleton variant="rect" width="100%" height={213} />
        </div>
        <div className={classes.wrapper}>
          <div className={classes.searchWrapperLoading}>
            <Typography variant="body2" className={classes.titleCard}>
              {t("home.where-do-you-want-to-stay")}
            </Typography>
            <div className={classes.areaSearch}>
              <div className={classes.searchLabelLoading}>
                <div style={{ marginRight: 10 }}>
                  <Skeleton
                    variant="rect"
                    width={24}
                    height={24}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div>
                  <Skeleton variant="text" width={172} animation="wave" />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", position: "relative" }}>
              <div className={classes.areaSearch}>
                <div className={classes.searchLabelLoading}>
                  <div style={{ marginRight: 10 }}>
                    <Skeleton
                      variant="rect"
                      width={24}
                      height={24}
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                  <div>
                    <Skeleton variant="text" width={172} animation="wave" />
                  </div>
                </div>
              </div>
              <div className={classes.totalNight}>
                <img
                  src="/img/moonlight.svg"
                  alt="moon"
                  style={{ marginRight: 4 }}
                />
                <div>
                  <Typography variant="caption">{t("home.night")}</Typography>
                </div>
              </div>
            </div>
            <div className={classes.areaSearch}>
              <div className={classes.searchLabelLoading}>
                <div style={{ marginRight: 10 }}>
                  <Skeleton
                    variant="rect"
                    width={24}
                    height={24}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div>
                  <Skeleton variant="text" width={172} animation="wave" />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "24px",
              }}
            >
              <Skeleton
                variant="rect"
                width={283}
                height={48}
                style={{ borderRadius: "8px" }}
              />
            </div>
          </div>
          <Skeleton
            variant="text"
            width={88}
            height={16}
            style={{ marginTop: "12px" }}
          />
          <div
            style={{
              position: "relative",
              marginTop: "21px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Skeleton
              variant="rect"
              width={330}
              height={121}
              style={{ borderRadius: "8px" }}
            />
            <div style={{ position: "absolute", left: "51px", bottom: "7px" }}>
              <Skeleton variant="text" width={116} />
              <Skeleton
                variant="text"
                width={102}
                style={{ marginTop: "4px" }}
              />
            </div>
          </div>
        </div>
        <div>
          <BottomNav />
        </div>
      </Container>
    </>
  );
}

export default HomePageEmbed;
