import { Container } from "@material-ui/core";
import dynamic from "next/dynamic";
import React, { Fragment } from "react";
import ErrorPage from "../src/components/ErrorPage";
import HomePageEmbed from "../src/components/HomePageEmbed";
import HomePageInstant from "../src/components/HomePageInstant";
const BottomNav = dynamic(() => import("../src/components/BottomNavigation"));

export default function Index() {
  const Homepage = () => {
    if (process.env.PWA_MODE === "embed") {
      return (
        <Fragment>
          <HomePageEmbed />
          <BottomNav />
        </Fragment>
      );
    }
    if (process.env.PWA_MODE === "instant") {
      return <ErrorPage error={404} />;
    }
    if (
      process.env.PWA_MODE !== "instant" &&
      process.env.PWA_MODE !== "embed"
    ) {
      return (
        <Fragment>
          <HomePageInstant />
          <BottomNav />
        </Fragment>
      );
    }
  };

  return (
    <Container maxWidth="xs">
      <Homepage />
    </Container>
  );
}
